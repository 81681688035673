import {inject, Injectable} from '@angular/core';
import {formatDate} from '@angular/common';
import {Vehicle} from '../models/vehicle';
import {JobInformation} from '../models/job-information';
import {FnpLanguageService} from './fnp-language.service';
import {MarketService} from './market.service';
import {ExcelBuilder} from '../utils/excel/builders/excel-builder';
import {ExcelWorksheetForm} from '../utils/excel/excel';
import * as Excel from 'exceljs/dist/exceljs.min.js';


@Injectable({
    providedIn: 'root'
})
export class FnpReportService {
    private readonly vehicleHeaderEntries = [
        {key: 'FNP.BUILD_DATE', prop: 'buildDateString'},
        {key: 'FNP.MODEL', prop: 'model'},
        {key: 'FNP.BODY_STYLE', prop: 'bodyStyle'},
        {key: 'FNP.ENGINE_TYPE', prop: 'engineStyle'},
        {key: 'FNP.TRANSMISSION', prop: 'transmission'},
        {key: 'FNP.DRIVE', prop: 'drive'}
    ];

    private readonly jobInfoHeaderEntries = [
        {key: 'FNP.MENU_NUMBER', prop: 'fcjMenuNumber'},
        {key: 'FNP.JOB_DESCRIPTION', prop: 'jobDescription'},
        {key: 'FNP.REGION_1', prop: 'region1Price'}
    ];

    private readonly jobInfoPrice2Entry = {key: 'FNP.REGION_2', prop: 'region2Price'};
    private readonly jobInfoPrice3Entry = {key: 'FNP.REGION_3', prop: 'region3Price'};

    private readonly EXCEL_PLACEHOLDER = 'FNP.NO_ITEMS_TO_SHOW';

    private vehicleHeaders: Excel.Column[];
    private jobInfoHeaders: Excel.Column[];

    private _languageService: FnpLanguageService = inject(FnpLanguageService);
    private _marketService: MarketService = inject(MarketService);

    private initHeaders(jobInfoOb: JobInformation | null): void {
        let headerBuilder = ExcelBuilder.getHeaderBuilder();
        this.vehicleHeaderEntries.forEach((e) => headerBuilder.addHeader(this._languageService.translate(e.key), e.prop));
        this.vehicleHeaders = headerBuilder.build();

        headerBuilder = ExcelBuilder.getHeaderBuilder();
        this.jobInfoHeaderEntries.forEach((e) => headerBuilder.addHeader(this._languageService.translate(e.key), e.prop));
        if (jobInfoOb?.region2Price) {
            headerBuilder.addHeader(this._languageService.translate(this.jobInfoPrice2Entry.key), this.jobInfoPrice2Entry.prop);
        }
        if (jobInfoOb?.region3Price) {
            headerBuilder.addHeader(this._languageService.translate(this.jobInfoPrice3Entry.key), this.jobInfoPrice3Entry.prop);
        }
        this.jobInfoHeaders = headerBuilder.build();
    }

    public exportFnpDataToExcel(vinNumber: string, currentVehicle: Vehicle, serviceData: JobInformation[],
                                maintenanceData: JobInformation[], repairData: JobInformation[]): void {
        this.initHeaders(serviceData[0] ?? maintenanceData[0] ?? repairData[0] ?? null);

        const excelBuilder = new ExcelBuilder();
        const vehicleWs = this.getVehicleWorksheet(excelBuilder, vinNumber, currentVehicle);
        const serviceWs = this.getJobWorksheet(excelBuilder, serviceData, 'FNP.SERVICE_MAINTENANCE');
        const maintenanceWs = this.getJobWorksheet(excelBuilder, maintenanceData, 'FNP.EXTENDED_MAINTENANCE');
        const repairWs = this.getJobWorksheet(excelBuilder, repairData, 'FNP.REPAIR_INFO');

        const date = formatDate(Date.now(), 'dd-MM-yyyy', 'en');
        excelBuilder.setName(`${this._languageService.translate('FNP.DATA_EXPORT')}_${date}`)
            .addExcelWorksheet(vehicleWs)
            .addExcelWorksheet(serviceWs)
            .addExcelWorksheet(maintenanceWs)
            .addExcelWorksheet(repairWs)
            .print();
    }

    public getVehicleWorksheet(excelBuilder: ExcelBuilder, vin: string, currentVehicle: Vehicle): ExcelWorksheetForm {
        const vehicleToExport: any = Object.assign({}, currentVehicle);
        vehicleToExport['buildDateString'] = currentVehicle.buildDate.toString().split(' ')[0].replaceAll('-', '/');

        return excelBuilder.getWorksheetBuilder()
            .setName(this._languageService.translate('FNP.VEHICLE_INFO'))
            .setHeaders(this.vehicleHeaders)
            .setRows([vehicleToExport])
            .setOutlinedHeader('medium')
            .setHeaderStyles([{prop: 'size', val: 10}, {prop: 'bold', val: true}])
            .insertRow({
                position: 1,
                values: [this._languageService.translate('FNP.VIN') + ':', vin],
                blankAfter: true,
                outlineStyle: 'thick',
                fontStyles: [{prop: 'size', val: '14'}, {prop: 'bold', val: true}]
            })
            .build();
    }

    public getJobWorksheet(excelBuilder: ExcelBuilder, data: JobInformation[], name: string): ExcelWorksheetForm {
        return excelBuilder.getWorksheetBuilder()
            .setName(this._languageService.translate(name))
            .setHeaders(this.jobInfoHeaders)
            .setRows(data)
            .setOutlinedHeader('medium')
            .setHeaderStyles([{prop: 'size', val: 10}, {prop: 'bold', val: true}])
            .setPriceColumns(['region1Price', 'region2Price', 'region3Price'])
            .setCurrency(this._marketService.currency)
            .setPlaceholder(this._languageService.translate(this.EXCEL_PLACEHOLDER))
            .insertRow({
                position: 1,
                values: ['', this._languageService.translate('FNP.PRICES_SHOWN')],
                blankAfter: true
            })
            .build();
    }
}
