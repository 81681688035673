import {inject, Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {FnpFile} from '../models/fnp-file';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {environment} from '../../config/environments/environment';
import {EffectiveDateDto} from '../models/effective-date-dto';
import {MarketService} from './market.service';

@Injectable({
    providedIn: 'root'
})
export class FileService {

    private _httpClient: HttpClient = inject(HttpClient);
    private _marketService: MarketService = inject(MarketService);

    public getServiceFiles(): Observable<FnpFile[]> {
        return this._httpClient.get<FnpFile[]>(`${environment.apiUrl}/service-files/${this.marketId}/all`);
    }

    public getActiveServiceFile(): Observable<HttpResponse<ArrayBuffer>> {
        return this._httpClient.get<ArrayBuffer>(`${environment.apiUrl}/service-files/${this.marketId}?status=active`,
            {responseType: 'blob' as 'json', observe: 'response'});
    }

    public uploadServiceFile(file: File, publicationDate: number): Observable<void> {
        const formParams = new FormData();
        formParams.append('file', file);
        formParams.append('publicationDate', publicationDate.toString());
        return this._httpClient.post<void>(`${environment.apiUrl}/service-files/${this.marketId}`, formParams);
    }

    public patchServiceFile(fileName: string, publicationDateTimestamp: number): Observable<void> {
        return this._httpClient.patch<void>(`${environment.apiUrl}/service-files/${this.marketId}/${fileName}`, publicationDateTimestamp);
    }

    public deleteServiceFile(fileName: string): Observable<void> {
        return this._httpClient.delete<void>(`${environment.apiUrl}/service-files/${this.marketId}/${fileName}`);
    }

    public getRepairFiles(): Observable<FnpFile[]> {
        return this._httpClient.get<FnpFile[]>(`${environment.apiUrl}/repair-files/${this.marketId}/all`);
    }

    public uploadRepairFile(file: File, publicationDate: number): Observable<void> {
        const formParams = new FormData();
        formParams.append('file', file);
        formParams.append('publicationDate', publicationDate.toString());
        return this._httpClient.post<void>(`${environment.apiUrl}/repair-files/${this.marketId}`, formParams);
    }

    public patchRepairFile(fileName: string, publicationDateTimestamp: number): Observable<void> {
        return this._httpClient.patch<void>(`${environment.apiUrl}/repair-files/${this.marketId}/${fileName}`, publicationDateTimestamp);
    }

    public deleteRepairFile(fileName: string): Observable<void> {
        return this._httpClient.delete<void>(`${environment.apiUrl}/repair-files/${this.marketId}/${fileName}`);
    }

    public getNewsletterFiles(): Observable<FnpFile[]> {
        return this._httpClient.get<FnpFile[]>(`${environment.apiUrl}/newsletters/${this.marketId}/all`);
    }

    public uploadNewsletterFile(file: File, publicationDate: number): Observable<void> {
        const formParams = new FormData();
        formParams.append('file', file);
        formParams.append('publicationDate', publicationDate.toString());
        return this._httpClient.post<void>(`${environment.apiUrl}/newsletters/${this.marketId}`, formParams);
    }

    public patchNewsletterFile(fileName: string, publicationDateTimestamp: number): Observable<void> {
        return this._httpClient.patch<void>(`${environment.apiUrl}/newsletters/${this.marketId}/${fileName}`, publicationDateTimestamp);
    }

    public deleteNewsletterFile(fileName: string): Observable<void> {
        return this._httpClient.delete<void>(`${environment.apiUrl}/newsletters/${this.marketId}/${fileName}`);
    }

    public getActiveRepairFile(): Observable<HttpResponse<ArrayBuffer>> {
        return this._httpClient.get<ArrayBuffer>(`${environment.apiUrl}/repair-files/${this.marketId}?status=active`,
            {responseType: 'blob' as 'json', observe: 'response'});
    }

    public getTransactionReport(from: number, to: number): Observable<HttpResponse<ArrayBuffer>> {
        return this._httpClient.get<ArrayBuffer>(`${environment.apiUrl}/markets/${this.marketId}/activity-report`,
            {responseType: 'blob' as 'json', observe: 'response', params: {from: from, to: to}});
    }

    public getEffectiveDate(): Observable<EffectiveDateDto> {
        return this._httpClient.get<EffectiveDateDto>(`${environment.apiUrl}/properties/${this.marketId}/effective-date`);
    }

    public get marketId(): number {
        return this._marketService.currentMarket?.id;
    }

}
