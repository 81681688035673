import {inject, Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Market, MarketCurrency, MarketPropName} from '../models/market';
import {AVAILABLE_MARKETS, DEFAULT_MARKET} from '../../config/markets/available-markets';
import {StateObservable} from '../utils/state-observable/state-observable';
import {StorageService} from './storage.service';

@Injectable({
    providedIn: 'root'
})
export class MarketService extends StateObservable {
    private readonly STORED_KEY = 'FNP_MARKET';

    private _currentMarket$: BehaviorSubject<Market>;
    private _storageService: StorageService = inject(StorageService);

    public setMarketById(marketId: number, checkCache = false): void {
        if (checkCache) {
            const cachedMarket = this._storageService.getStored(this.STORED_KEY);
            if (cachedMarket) marketId = cachedMarket;
        }
        this.setMarketBy('id', marketId);
    }

    private setMarketBy(marketParam: MarketPropName, marketParamValue: number | string): void {
        let market = AVAILABLE_MARKETS.find(m => m[marketParam] == marketParamValue);
        if (!market) market = DEFAULT_MARKET;
        this.market = market;
    }

    private set market(market: Market) {
        if (!this._currentMarket$) {
            this._currentMarket$ = new BehaviorSubject<Market>(market);
        } else {
            this._currentMarket$.next(market);
        }
        this._storageService.setStored(this.STORED_KEY, market.id);
        this.trigger();
        // TODO remove after debug
        console.log('CURRENT MARKET:  ' + this._currentMarket$.getValue().name);
        console.log(this._currentMarket$.getValue());
    }

    public get currentMarket(): Market {
        return this._currentMarket$?.getValue() ?? DEFAULT_MARKET;
    }

    public get privacyPolicyUrl(): string {
        return this.currentMarket.privacyPolicyUrl;
    }

    public get currency(): MarketCurrency {
        return this.currentMarket.currency;
    }

    public asyncCurrentMarket(): BehaviorSubject<Market> {
        return this._currentMarket$;
    }
}
