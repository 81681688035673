import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MarketService} from '../../../services/market.service';

@Component({
    selector: 'app-introduction-and-map',
    templateUrl: './introduction-and-map.component.html',
    styleUrls: ['./introduction-and-map.component.scss']
})
export class IntroductionAndMapComponent implements OnInit, OnDestroy {

    private _marketService: MarketService = inject(MarketService);
    private _subscription$: Subscription;

    public marketCode: string;

    public ngOnInit() {
        this.initMarketWatcher();
    }

    public ngOnDestroy() {
        this._subscription$.unsubscribe();
    }

    private initMarketWatcher() {
        this._subscription$ = this._marketService.asyncCurrentMarket().subscribe({
            next: (mar) => {
                this.marketCode = mar.shortname?.toLowerCase();
            }
        });
    }

}
