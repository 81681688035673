import {Component, inject} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth.service';
import {RouteService} from '../../services/route.service';
import {MarketService} from '../../services/market.service';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent {

    public privacyPolicyUrl: string;

    private _authService: AuthService = inject(AuthService);
    private _routeService: RouteService = inject(RouteService);
    private _marketService: MarketService = inject(MarketService);
    private _router: Router = inject(Router);

    public redirectToPrivacyPolicy(): void {
        if (this._authService.isCurrentlyLogged()) {
            window.open(this._marketService.privacyPolicyUrl, '_blank');
        } else {
            this._router.navigate(['/privacy-policy']);
        }
    }

    public get userName(): string {
        return this._authService.userName;
    }

    public get isCurrentRoutePrivacyPolicy(): boolean {
        return this._routeService.currentRoute.split('?')[0] === '/privacy-policy';
    }


}
