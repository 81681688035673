import {Market} from '../../app/models/market';

export type availableMarketNames = 'Germany' | 'Austria' | 'Switzerland';
export type availableMarketShortnames = 'DE' | 'AUS' | 'CH';
export const AVAILABLE_MARKETS: Market[] = [
    {
        name: 'Germany',
        shortname: 'DE',
        id: 1,
        privacyPolicyUrl: 'https://www.ford.de/nuetzliche-informationen/geschaeftsbedingungen-und-datenschutz/datenschutzrichtlinie',
        currency: {
            symbol: '\u20AC',
            position: 'post'
        }
    },
    {
        name: 'Austria',
        shortname: 'AUS',
        id: 2,
        privacyPolicyUrl: 'https://www.google.com/search?q=AUS+Privacy+Policy',
        currency: {
            symbol: '\u20AC',
            position: 'post'
        }
    },
    {
        name: 'Switzerland',
        shortname: 'CH',
        id: 3,
        privacyPolicyUrl: 'https://www.google.com/search?q=CH+Privacy+Policy',
        currency: {
            symbol: 'CHF',
            position: 'pre'
        }
    },
    {
        name: 'United Kingdom',
        shortname: 'UK',
        id: 4,
        privacyPolicyUrl: 'https://www.google.com/search?q=UK+Privacy+Policy',
        currency: {
            symbol: '\u00A3',
            position: 'pre'
        }
    }
];
export const DEFAULT_MARKET: Market = {
    name: 'Germany',
    shortname: 'DE',
    id: 1,
    privacyPolicyUrl: 'https://www.ford.de/nuetzliche-informationen/geschaeftsbedingungen-und-datenschutz/datenschutzrichtlinie',
    currency: {
        symbol: '\u20AC',
        position: 'post'
    }
};

export enum AVAILABLE_MARKETS_ENUM {
    GERMANY = 1,
    AUSTRIA = 2,
    SWITZERLAND = 3,
    UNITED_KINGDOM = 4
}
