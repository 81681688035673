<div class="intro-page">
    <div class="intro-page__introduction">
        <div class="intro-page__introduction__title">
            <h1>{{'INTRO.WELCOME_TO_FORDPRO_SERVICE_OFFERS_FOR_FLEETS' | translate}}</h1>
        </div>
        <div class="intro-page__introduction__definitions">
            <p>{{"INTRO.DEFINITIONS_TXT" | translate}}</p>
        </div>
        <div class="intro-page__introduction__list">
            <p class="intro-page__introduction__list__title">{{"INTRO.LIST_TITLE" | translate}}</p>
            <p class="intro-page__introduction__list__el__title">{{"INTRO.LIST_1_TITLE" | translate}}</p>
            <p class="intro-page__introduction__list__el__txt">
                <span class="intro-page__introduction__list__el__txt--hl">{{"INTRO.LIST_1_DESC_HL" | translate}}</span>
                {{"INTRO.LIST_1_DESC" | translate}}
            </p>
            <p class="intro-page__introduction__list__el__title">{{"INTRO.LIST_2_TITLE" | translate}}</p>
            <p class="intro-page__introduction__list__el__txt">
            <span class="intro-page__introduction__list__el__txt--hl">{{"INTRO.LIST_2_DESC_HL" | translate}}</span>
                {{"INTRO.LIST_2_DESC" | translate}}
            </p>
        </div>
        <div class="intro-page__introduction__summary">
            <p>{{"INTRO.SUMMARY" | translate}}</p>
        </div>
    </div>
    <div class="intro-page__map">
        <img class="intro-page__map__img"
             src="assets/img/fnp-{{marketCode}}-map.png"
             alt="Germany map">
    </div>
</div>
